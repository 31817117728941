.first-load {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: initialLoad 2s ease-in-out 2, moveup 1s ease-in-out 4s;
  animation-fill-mode: forwards;
}

@keyframes initialLoad {
  0% {opacity: 1;}
  50% {opacity: 0.05;}
  100% {opacity: 1}
}

@keyframes moveup {
  100% {height: 25%}
}

#divider {
  /* top: 35%; */
  top: 25%;
  position: absolute;
  animation: expand-divider 0.7s ease-in 5.65s;
  animation-fill-mode: forwards;
  /* position: absolute;
  z-index: 999; */
}

@keyframes expand-divider {
  0% {width: 0%; left: 50%;}
  100% {width: 46%; left: 27%;}
}